<template>
  <div class="ipl-vip-layout-background" :class="$vuetify.breakpoint.mdAndUp ? 'padding-desktop' : 'px-2'">
    <router-view></router-view>
  </div>
</template>
<script>
import { locale, uiHelper } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'

export default {
  name: 'iplVipLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.default_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.default_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.default_keyword')}` }]
  },
  data: () => ({}),
  methods: {
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER,
        params: { lang: this.$route.params.lang }
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN,
        params: { lang: this.$route.params.lang }
      })
    }
  }
}
</script>

<style lang="scss">
.padding-desktop {
  padding: 0px 12% !important;
}
.ipl-vip-layout-background {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  margin: 0 auto;
  background-size: 100%;
  background: url('/static/image/background/bb88_ipl_bpass_bg-min.jpg');
}
</style>
